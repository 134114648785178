import React, { useState, useRef, useEffect } from 'react'
// import classNames from 'classnames'
// import { BasicProps } from '@/components/types'
import queryString from 'query-string'

import icon from '@/assets/common/memberTaskTimerNew.png'
import { APP_SCHEME_H5 } from '@/config/constants'
import { apis } from '@/common/http-client'
import session from '@/common/session'
import { Toast } from '@/components/Toast'
import { element } from '@/common/mall-tracking'
import { ElementType } from '@/common/mall-tracking/types'
const { VITE_DOMAIN } = import.meta.env
import { isInApp, isInMiniProgram, urlParse } from '@/utils'

import styles from './index.module.less'

/** 任务类型 */
enum TaskType {
  /** 页面浏览 */
  'timer' = '14'
}

// 从url里获取对应的参数

const {
  memberActivityTaskType,
  pageViewLimitTime = '0',
  pageViewCurrentTime = '0',
  pagecontentid = '',
  activityId = '',
  activityName = ''
} = urlParse()

/** 计时任务已经历的时间（毫秒），只有在纯计时模式会出现） */
const defaultTime = Number(pageViewCurrentTime) * 1000 || 0
/** 计时器的持续时间（毫秒） */
const maxTime = Number(pageViewLimitTime) * 1000 - defaultTime || 0
const browsingText = `浏览${maxTime / 1000}s`
const finishText = '返回活动页'
/** 埋点上报的函数 */
function report(staytime = 0) {
  console.log('会员任务上报数据，已经历时间：', staytime)

  apis.task.taskReport({
    events: [
      {
        eventData: JSON.stringify({
          articleType: 'design-discover',
          articleId: pagecontentid,
          staytime: Math.round(staytime / 1000),
          appLink: `${APP_SCHEME_H5}${encodeURIComponent(window.location.href)}`
        }),
        tradeNo: '',
        uid: session.getUid(),
        eventTimestamp: '',
        eventType: memberActivityTaskType
      }
    ]
  })
}

let MemberTaskTimer = () => {
  const [finished, setFinished] = useState(maxTime === 0) // 如果是计次数的模式，则把finished设为true
  const [visible, setVisible] = useState(true)
  const progressElement = useRef<HTMLDivElement>(null)
  const stayTime = useRef(0)
  const stop = useRef(false)
  useEffect(() => {
    if (maxTime > 0) {
      const startTime = new Date().valueOf()
      const element = progressElement.current as HTMLDivElement

      // 每一帧都更新进度条
      const tick = () => {
        if (stop.current) return

        const now = new Date().valueOf()
        stayTime.current = now - startTime
        const nextPercent = stayTime.current / maxTime
        element.style.width = `${nextPercent * 100}%`

        if (stayTime.current >= maxTime) {
          console.log('定时器计时完成')
          setFinished(true)
          report(stayTime.current)
          Toast.success('浏览时长完成')
        } else {
          window.requestAnimationFrame(tick)
        }
      }

      window.requestAnimationFrame(tick)
    } else {
      console.log('会员任务无需计时，直接上报')
      report()
    }
  }, [])

  const samsWebUrl = VITE_DOMAIN

  // 监听openWindow事件，终止计时器
  useEffect(() => {
    // 如果已经计时完成，则不要管页面离开事件
    if (!finished) {
      const receiveMessage = () => {
        console.log('离开当前页面onCloseWindow')
        report(stayTime.current) // 埋点上报
        stop.current = true // 终止定时器
        setVisible(false) // 隐藏浮层
      }
      // window.addEventListener('openWindow', receiveMessage, false);
      window.addEventListener('onCloseWindow', receiveMessage, false)
      return () => {
        // window.removeEventListener('openWindow', receiveMessage, false);
        window.removeEventListener('onCloseWindow', receiveMessage, false)
      }
    }
  }, [finished])

  if (!visible) return null
  return (
    <div
      onClick={() => {
        if (finished) {
          sr.app.invoke('openWindow', {
            openUrl: `${samsWebUrl}/sams/member-task-v2?activityId=${activityId}`
          })
          element(
            {
              type: ElementType.Tap,
              elementId: 'callback_activity'
            },
            {
              activityInfo: { activityId, activityName },
              page_type: '活动装修页',
              page_title: activityName,
              page_id: activityId,
              element_name: '成为会员'
            }
          )
        }
      }}
      className={styles.memberTaskTimer}
    >
      {Number(pageViewLimitTime) > 0 && (
        <>
          {/* 图标 */}
          <img src={icon} />
          {/* 文字 */}
          <div className={styles.text}>
            {/* 进度条 */}
            <div className={styles.progress}>
              <div className={styles.progressInner} ref={progressElement} style={{ width: 0 }} />
            </div>
            {finished ? finishText : browsingText}
          </div>{' '}
        </>
      )}
    </div>
  )
}

/** 当前组件只处理memberActivityTaskType等于14的情况 */
if (!isInApp() || memberActivityTaskType !== '14') {
  MemberTaskTimer = () => <div />
}

export default React.memo(MemberTaskTimer)
